import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';

import isFunction from 'utilities/isFunction';

import useData from './hooks/useData';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import CloseAndCreateCaseMenuListItem from 'components/CloseAndCreateCaseMenuListItem';

const CloseActivityAndCreateCaseMenuListItem = ({
    activityId,
    activityRecord,
    activitySchema,
    groups,
    onClick,
    onSave,
    referencedUsers,
    riskLabel,
    userId
}) => {
    const [closedActivity, closeActivity, reset] = useData(activitySchema);
    const {useActivities} = useGlobalStateHooks();
    const [_activities, setActivities] = useActivities();

    const { HOME } = useConfig();

    const navigate = useNavigate();

    const handleActivityClose = useCallback(async() => {
        let userToClose;
        let closedAssignmentType;
        loop1:
        for (let assignmentType in referencedUsers.current){
            const {users: assignmentUsers, status} = referencedUsers.current[assignmentType];
            if (status === 'inprogress'){
                for (let user of assignmentUsers){
                    if (user.lastName === 'Group' && groups.current.includes(user.id)){
                        userToClose = user;
                        closedAssignmentType = assignmentType;
                        break loop1;
                    }else if (user.id === Number(userId)){
                        userToClose = user;
                        closedAssignmentType = assignmentType;
                        break loop1;
                    }
                }
            }
        }

        const dataToSubmit = {
            activityId,
            status: 'complete'
        }
        if (userToClose.lastName === 'Group'){
            dataToSubmit.groupId = userToClose.id;
        }else{
            dataToSubmit.userId = userToClose.id;
        }
        dataToSubmit.assignmentType = closedAssignmentType;
        dataToSubmit.userName = `${userToClose.firstName} ${userToClose.lastName}`;
        await closeActivity(dataToSubmit);

        if (isFunction(onSave)) {
            onSave();
        }
    }, [activityId, closeActivity, onSave, groups, referencedUsers, userId]);

    useEffect(() => {
        if (!closedActivity) {
            return;
        }

        setActivities({});

        if (isFunction(onClick)) {
            onClick(closedActivity);
        }

        setTimeout(() => {
            navigate(HOME);
        }, 1000);

        reset();
    }, [HOME, closedActivity, navigate, onClick, reset, setActivities]);
    return (
        <CloseAndCreateCaseMenuListItem
            activityRecord={activityRecord}
            linkSchema={activitySchema}
            onSave={handleActivityClose}
            riskLabel={riskLabel}
        />
    );
};

export default CloseActivityAndCreateCaseMenuListItem;
