import DatePicker from 'components/DatePicker';
import DropdownMultiSelect from 'components/DropdownMultiSelect';
import PanelHeader from 'components/PanelHeader';
import SearchInput from 'components/SearchInput';
import { Toggle } from 'components/Toggle';
import { useGetActivitiesFiltersValues } from 'queries/activities.query';
import { toDateString } from 'utilities/common.util';
import styles from './activity-panel-header-styles.module.scss';

const translations = {
    activityTypes: 'Activity Types',
    assignees: 'Assignees',
    endDate: 'Due Date (To)',
    entities: 'Entities',
    recurrence: 'Recurrence',
    reviewers: 'Reviewers',
    search: 'Search',
    startDate: 'Due Date (From)',
    status: 'Status'
};

export const ActivitiesPanelHeader = ({
    className = '',
    endDate,
    isFlagged,
    isAssignedToMe = true,
    onChangeActivityType,
    onChangeAssignees,
    onChangeEndDate,
    onChangeEntities,
    onChangeRecurrence,
    onChangeReviewers,
    onChangeSearch,
    onChangeStartDate,
    onChangeStatus,
    onToggleIsFlagged,
    onToggleIsMe,
    schemas = [],
    startDate
}) => {
    const { data = {} } = useGetActivitiesFiltersValues({ schemas });

    const {
        activityEntities = [],
        activityAssignees = [],
        activityReviewers = [],
        activityStatus = [],
        activityTypes = [],
        recurrenceTypes = []
    } = data;

    const getItems = items =>
        items.map(({ _count = 0, name = '' }) => ({
            label: name,
            value: name
        }));

    return (
        <PanelHeader
            className={`${className} ${styles.addActivitiesPanelHeader}`}
        >
            <div className={styles.activitiesViewSelectorContainer}>
                <div className={styles.activitiesViewSelector}>
                    <SearchInput
                        className={styles.search}
                        onChange={onChangeSearch}
                        placeholder={translations.search}
                    />

                    <div className={styles.toggleWrap}>
                        <Toggle
                            disableText="All"
                            enableText="Me"
                            isActive={isAssignedToMe}
                            onToggle={onToggleIsMe}
                        />

                        <Toggle
                            color="orange"
                            enableText="Flagged"
                            isActive={isFlagged}
                            onToggle={onToggleIsFlagged}
                        />
                    </div>
                </div>

                <div
                    className={`${className} ${styles.activitiesViewSelector}`}
                >
                    <DropdownMultiSelect
                        className={styles.categoryDropdown}
                        items={getItems(activityStatus)}
                        label={translations.status}
                        onChange={onChangeStatus}
                        returnObject
                    />

                    <DropdownMultiSelect
                        className={styles.categoryDropdown}
                        items={getItems(activityTypes)}
                        label={translations.activityTypes}
                        onChange={onChangeActivityType}
                        returnObject
                    />

                    <DropdownMultiSelect
                        className={styles.categoryDropdown}
                        items={getItems(activityAssignees)}
                        label={translations.assignees}
                        onChange={onChangeAssignees}
                        returnObject
                    />

                    <DropdownMultiSelect
                        className={styles.categoryDropdown}
                        items={getItems(activityReviewers)}
                        label={translations.reviewers}
                        onChange={onChangeReviewers}
                        returnObject
                    />

                    <DatePicker
                        className={styles.startDate}
                        label={translations.startDate}
                        onChange={date => onChangeStartDate(date.toISOString())}
                        placeholder={toDateString(startDate)}
                        value={startDate}
                    />

                    <DatePicker
                        className={styles.endDate}
                        label={translations.endDate}
                        onChange={date => onChangeEndDate(date.toISOString())}
                        placeholder={toDateString(endDate)}
                        value={endDate}
                    />

                    <DropdownMultiSelect
                        className={styles.categoryDropdown}
                        items={getItems(activityEntities)}
                        label={translations.entities}
                        onChange={onChangeEntities}
                        returnObject
                    />

                    <DropdownMultiSelect
                        className={styles.recurrencyDropdown}
                        items={getItems(recurrenceTypes)}
                        label={translations.recurrence}
                        onChange={onChangeRecurrence}
                        returnObject
                    />
                </div>
            </div>
        </PanelHeader>
    );
};
