import dateToDateString from 'utilities/dateToDateString';
import formatDate from 'utilities/formatDate';

const mapUser = user => {
    const {
        first_name: firstName,
        id,
        last_name: lastName,
        profile_photo: profilePhoto
    } = user ?? {};

    return {
        firstName,
        id,
        lastName,
        profilePhoto
    };
};

const kebabCase = text => text.toLowerCase().replace(/\s/g, '-');

const normalizeDropdown = items =>
    items.map(item => {
        if (typeof item === 'string') {
            return {
                label: item,
                value: kebabCase(item)
            };
        }

        return item;
    });

const normalizeDropdowns = (items, selections) =>
    items.reduce((accumulator, item) => {
        const dropdownCopy = { ...item };

        dropdownCopy.options = normalizeDropdown(dropdownCopy.options);

        if (!('id' in dropdownCopy)) {
            dropdownCopy.id = kebabCase(dropdownCopy.title);
        }
        dropdownCopy.selectedIndex = selections[dropdownCopy.id]
        accumulator.push(dropdownCopy);

        return accumulator;
    }, []);

const resolveAttachments = activityAttachments =>
    activityAttachments.reduce((accumulator, attachments) => {
        const { deleted, file, id, name, uploaded_timestamp } = attachments;

        if (!deleted) {
            accumulator.push({
                deleted,
                file,
                id,
                name,
                uploaded_timestamp,
                url: file
            });
        }

        return accumulator;
    }, []);

const resolveComments = ({activityComments, baseSchema, querySchemas}) =>
    activityComments.reduce((accumulator, comments) => {
        const {
            created_at: createdAt,
            deleted,
            id,
            text: content,
            user_comments: userComments = []
        } = comments;

        if (userComments.length === 0) {
            return [];
        }

        const { tenant_user:tenantUser } = userComments[0];

        const userId = tenantUser?.id

        var userArray;
        if(tenantUser){
            if(querySchemas && querySchemas.length > 0){
                for(const {value:schema} of querySchemas){
                    if(tenantUser[`user_${schema}`]){
                        userArray = tenantUser[`user_${schema}`]
                                    //  : (userGroup)
                                    // ? {'first_name': userGroup.name, 'last_name': 'Group', 'profile_photo': ''} :
                                    // {'first_name': '', 'last_name': '', 'profile_photo': ''}
                    }
                }
                if(!userArray){
                    userArray = tenantUser[`user_${baseSchema}`];
                }
            }else{
                userArray = tenantUser[`user_${baseSchema}`];
            }
        }else{
            userArray = {first_name:'', last_name:''};
        }

        const {first_name, last_name} = userArray;

        const authorName = `${first_name} ${last_name}`;
        
        const commentUser = {id: userId, name: authorName};

        accumulator.push({
            content,
            createdAt: createdAt,
            deleted,
            id,
            user: commentUser
        });

        return accumulator;
    }, []);

const resolveEntities = activityEntities =>
    activityEntities.reduce((accumulator, entities) => {
        const { name } = entities.entity;

        accumulator.push(name);

        return accumulator;
    }, []);

const resolveForms = groupForms =>
    groupForms.reduce((accumulator, form) => {
        const { assignment, assignment_type, form: formDetails } = form;

        const { form_questions: formQuestions, id: formId, name: formName } = formDetails

        const questions = formQuestions.reduce(
            (accumulator, questions) => {
                const { id, required, question } = questions;

                const { formComponent, index, name, questionId, type, choices } = question;

                const questionChoices = choices.reduce((accumulator, choice) => {
                    accumulator.push({
                        isChecked: false,
                        label: choice?.text,
                        name: name,
                        value: kebabCase(choice?.text)
                    })
                    return accumulator;
                }, []);

                accumulator.push({
                    choices: questionChoices,
                    formComponent,
                    id,
                    index,
                    name,
                    questionId,
                    required,
                    type
                });
                return accumulator;
            },
            []
        );

        accumulator = {
            assignment,
            assignment_type,
            formId,
            formName,
            questions: questions.sort((a,b) => {
                if (a?.index === b?.index) {
                    return 0;
                }
                if (a?.index === null) {
                    return 1;
                }
                if (b?.index === null) {
                    return -1;
                }
                return a?.index < b?.index ? -1 : 1;
              })
        };

        return accumulator;
    }, []);

const resolveAnswers = ({baseSchema, groupForms, querySchemas}) =>
    groupForms.reduce((accumulator, form) => {
        const { assignment, assignment_type, form: formDetails } = form;

        const {
            form_questions: formQuestions,
            id: formId,
            name: formName
        } = formDetails

        const questions = formQuestions.reduce(
            (accumulator, questions) => {
                const { id, required, question, activity_form_answers: formAnswers } = questions;

                const { formComponent, index, name, questionId, type } = question;

                const answers = formAnswers.reduce(
                    (accumulator, answer) => {
                        const {
                            id: answerId,
                            user_id: userId,
                            question_id: questionId,
                            answer: questionAnswer,
                            tenant_user: tenantUser 
                        } = answer;

                        var user;
                        if(tenantUser){
                            if(querySchemas && querySchemas.length > 0){
                                for(const {value:s} of querySchemas){
                                    if(tenantUser[`user_${s}`]){
                                        user = tenantUser[`user_${s}`]
                                                    //  : (userGroup)
                                                    // ? {'first_name': userGroup.name, 'last_name': 'Group', 'profile_photo': ''} :
                                                    // {'first_name': '', 'last_name': '', 'profile_photo': ''}
                                    }
                                }
                                if(!user){
                                    user = tenantUser[`user_${baseSchema}`];
                                }
                            }else{
                                user = tenantUser[`user_${baseSchema}`];
                            }
                        }else{
                            user = {first_name:'', last_name:''};
                        }

                        const {first_name: firstName, last_name: lastName} = user;
                        const answerUser = {id: userId, name: `${firstName} ${lastName}`}
                        accumulator.push({answerUser, id:answerId, questionAnswer, questionId, userId});
                        return accumulator;
                    }, []
                );

                answers.sort((a,b) => a.id - b.id);

                accumulator.push({
                    answers,
                    formComponent,
                    id,
                    index,
                    name,
                    questionId,
                    required,
                    type
                });
                return accumulator;
            },
            []
        );

        accumulator = {
            assignment,
            assignment_type,
            formId,
            formName,
            questions
        };

        return accumulator;
    }, []);

const resolveGroups = ({activityUsers, baseSchema, querySchemas}) => {
        if(activityUsers && Array.isArray(activityUsers)){
            return activityUsers.reduce((accumulator, user) => {
                const {
                    assignment_type: group,
                    status,
                    tenant_user: userDetails,
                    group: userGroup
                } = user;

                const { id } = (userDetails) || (userGroup) ? (userDetails) ? userDetails : userGroup : { id:undefined };

                if(id){
                    var userArray;
                    if(userDetails){
                        if(querySchemas && querySchemas.length > 0){
                            for(const {value:schema} of querySchemas){
                                if(userDetails[`user_${schema}`]){
                                    userArray = userDetails[`user_${schema}`];
                                }
                            }
                            if(!userArray){
                                userArray = userDetails[`user_${baseSchema}`];
                            }
                        }else{
                            userArray = userDetails[`user_${baseSchema}`];
                        }
                    }

                    const {
                        first_name: firstName,
                        last_name: lastName,
                        profile_photo: profilePhoto,
                    } = userArray && 'first_name' in userArray ? userArray : {first_name: '', last_name: '', profile_photo: ''};

                    const values = {
                        firstName,
                        id,
                        lastName,
                        profilePhoto,
                        status,
                        type: (userDetails) ? 'user' : 'group'
                    };

                    if (!(group in accumulator)) {
                        accumulator[group] = { users: [] };
                    }

                    accumulator[group].users.push(values);
                }

                return accumulator;
            }, {});
        }else{
            return {};
        }
    }

const resolveHistory = ({activityHistory, querySchemas, schema}) =>
    activityHistory.reduce((accumulator, history) => {
        const { color, created_at: createdAt, message, tenant_user: tenantUser } = history;

        var user;
        if(querySchemas && querySchemas.length > 0){
            for(const {value:s} of querySchemas){
                if(tenantUser[`user_${s}`]){
                    user = tenantUser[`user_${s}`]
                }
            }
            if(!user){
                user = tenantUser[`user_${schema}`];
            }
        }else{
            user = tenantUser[`user_${schema}`];
        }
        const { firstName, lastName } = mapUser(user);

        const date = new Date(createdAt);
        const text = `${firstName ?? ''} ${lastName ?? ''} ${message}`.trim();

        accumulator.push({
            color,
            createdAt: date,
            date: formatDate(date),
            text
        });

        return accumulator;
    }, []);

const resolveUsers = groups =>
    Object.keys(groups)
        .sort()
        .reduce((acc, key) => {
            acc[key] = groups[key];

            return acc;
        }, {});

const resolvePolicies = activityPolicies =>
    activityPolicies.reduce((accumulator, { document: policy, section }) => {

        if (section == null){
            accumulator.push({description: policy?.description, id: `${policy?.id}.0`, name: policy?.name, riskLevel: policy?.risk_label});
        }else{
            accumulator.push({description: section?.description, id: `${policy?.id}.${section?.id}`, name: section?.name, riskLevel: section?.riskLevel})
        }

        return accumulator;
    }, []);

const determineStatus = (activity) => {
    if (activity.closed) {
        return 'completed';
    }
    const dueDate = new Date(activity.due_date);
    dueDate.setHours(23);
    const startDate = new Date(activity.start_date);
    const now = new Date();
    if (dueDate < now) {
        return 'overdue';
    }
    if (startDate <= now) {
        return 'inProgress';
    }
    return 'upcoming';
};

const normalizeData = ({ data, querySchemas, schema, user }) => {
    const activityData = data[`${schema}_activity`].reduce(
        (accumulator, activity) => {
            const {
                activity_attachments: activityAttachments,
                activity_entities: activityEntities,
                activity_forms: activityForms,
                activity_histories: activityHistory,
                activity_recurrences: activityRecurrences,
                activity_type: activityType,
                deleted,
                dropdown_selections: dropdownSelections,
                comments: activityComments,
                closed,
                activity_policies: activityPolicies,
                due_date: activityDueDate,
                id: activityId,
                instructions: activityInstructions,
                is_hierarchical: hierarchical,
                linkages: activityLinkages,
                linkagesByActivityId2,
                name: activityName,
                risk_label: activityRiskLabel,
                send_email: sendEmail,
                start_date: activityStartDate,
                user_activities: activityUsers,
                group_completion_rate: groupCompletionRate
            } = activity;

            const attachments = resolveAttachments(activityAttachments);
            const entities = resolveEntities(activityEntities);
            const comments = resolveComments({activityComments, baseSchema:schema, querySchemas});
            const policies = resolvePolicies(activityPolicies);
            const groups = resolveGroups({activityUsers:activityUsers.filter(({status})=> status !== 'removed'), baseSchema:schema, querySchemas});
            const allPreviouslyAssignedGroups = resolveGroups({activityUsers, baseSchema:schema, querySchemas});
            const users = resolveUsers(groups);
            const allPreviouslyAssignedUsers = resolveUsers(allPreviouslyAssignedGroups);

            for (const [index, [key]] of Object.entries(
                Object.entries(users)
            )) {
                const group = users[key].users;
                var groupProgress;

                const completionRate = groupCompletionRate ? groupCompletionRate : Array.from({length: index+1}, () => 1);

                if (
                    typeof completionRate[index] === 'string' &&
                    completionRate[index] === 'all'
                ) {
                    groupProgress = group.some(
                        ({ status }) => status !== 'complete'
                    );
                } else if (
                    typeof completionRate[index] === 'number' &&
                    completionRate[index] > 1
                ) {
                    const complete = group.filter(
                        ({ status }) => status === 'complete'
                    );
                    groupProgress =
                        (complete.length / group.length) * 100 >=
                        completionRate['index'];
                } else {
                    groupProgress = group.every(
                        ({ status }) => status !== 'complete'
                    );
                }

                users[key]['status'] = groupProgress
                    ? 'inprogress'
                    : 'complete';
            }

            const inProgress = Object.entries(users).some(
                array => array[1].status !== 'complete'
            );
            const userRecord = Object.entries(users).filter(array => {
                const u = array[1].users.filter(obj =>
                    obj.id ? obj.id.toString() === user : 0
                );
                return u.length > 0;
            });
            const userGroup =
                userRecord && userRecord[0] && userRecord[0][0]
                    ? userRecord[0][0]
                    : '';

            const userProgress =
                userRecord && userRecord[0] && userRecord[0][1]
                    ? userRecord[0][1].status
                    : 'inprogress';

            const status = inProgress ? 'pending' : 'complete';
            const type = activityType.name;
            const riskLabel = activityType.risk_label;

            const startDate = new Date(activityStartDate);
            const dueDate = new Date(activityDueDate);

            const activityLinksOne = activityLinkages ? activityLinkages.reduce((accumulator, activityLink) => {
                const newLink = {
                    id: activityLink?.activityByActivityId2?.id,
                    name: activityLink?.activityByActivityId2?.name
                }
                if(!activityLink?.activityByActivityId2?.deleted){
                    if(newLink?.id){
                        accumulator?.activityLinks.push(newLink);
                    }
                }
                const newCaseLink = {
                    id: activityLink?.case?.id,
                    name: activityLink?.case?.case_name
                }
                if(newCaseLink?.id){
                    accumulator?.caseLinks.push(newCaseLink);
                }
                if(activityLink?.log){
                    accumulator?.logLinks.push({id:activityLink?.log?.id, name:activityLink?.log?.log_types[0]?.log_type?.name});
                }
                return accumulator;
            }, {activityLinks: [], caseLinks: [], logLinks: []}) : {activityLinks: [], caseLinks: [], logLinks: []};
            const activityLinksTwo = linkagesByActivityId2 ? linkagesByActivityId2.reduce((accumulator, activityLink) => {
                const newLink = {
                    id: activityLink?.activity?.id,
                    name: activityLink?.activity?.name
                }
                if(!activityLink?.activity?.deleted){
                    if(newLink?.id){
                        accumulator?.activityLinks.push(newLink);
                    }
                }
                const newCaseLink = {
                    id: activityLink?.case?.id,
                    name: activityLink?.case?.case_name
                }
                if(newCaseLink?.id){
                    accumulator?.caseLinks.push(newCaseLink);
                }
                if(activityLink?.log){
                    accumulator?.logLinks.push({id:activityLink?.log?.id, name:activityLink?.log?.log_types[0]?.log_type?.name});
                }
                return accumulator;
            }, {activityLinks: [], caseLinks: [], logLinks: []}) : {activityLinks: [], caseLinks: [], logLinks: []};

            const recurrenceLinks = activityRecurrences ? activityRecurrences.reduce((accumulator, recurrenceLink) => {
                const recurrences = recurrenceLink?.activity_recurrence?.activity_recurrences;
                const recurringActivities = recurrences.reduce((accumulator, recurrence) => {
                    if(activityId !== recurrence?.activity?.id){
                        accumulator?.links.push({
                            id: recurrence?.activity?.id,
                            name: `${recurrence?.activity?.name} - Due ${dateToDateString(new Date(recurrence?.activity?.due_date))}`,
                            status: determineStatus(recurrence?.activity)
                        });
                    }
                    accumulator?.recurrences.push({
                        dueDate: recurrence?.activity?.due_date,
                        id: recurrence?.activity?.id,
                        name: recurrence?.activity?.name,
                        startDate: recurrence?.activity?.start_date
                    });
                    return accumulator;
                }, {links: [], recurrences: []});
                accumulator?.links.push(...recurringActivities?.links);
                accumulator?.recurrences.push(...recurringActivities?.recurrences);
                return accumulator
            }, {links: [], recurrences: []}) : {links: [], recurrences: []};

            const activityRecurrence = {
                activityTypeId: activityRecurrences[0]?.activity_recurrence?.activity_type_id,
                attachments: activityRecurrences[0]?.activity_recurrence?.activity_recurrence_attachments.reduce((accumulator, attachment) => {
                        accumulator.push({file: attachment?.file, id: attachment?.id, name: attachment?.name });
                        return accumulator;
                    }, []),
                entity: activityRecurrences[0]?.activity_recurrence?.activity_recurrence_entities.reduce((accumulator, entity) => {
                        accumulator.push(entity?.entity?.id);
                        return accumulator;
                    }, []),
                forms: activityRecurrences[0]?.activity_recurrence?.activity_recurrence_forms.reduce((accumulator, form) => {
                        accumulator.push({assignment: form?.assignment, assignmentType: form?.assignment_type, dueDate: form?.due_date, id: form?.form?.id, name: form?.form?.name });
                        return accumulator;
                    }, []),
                groupCompletionRate: activityRecurrences[0]?.activity_recurrence?.group_completion_rate,
                hierarchical: activityRecurrences[0]?.activity_recurrence?.is_hierarchical,
                id: activityRecurrences[0]?.activity_recurrence?.id,
                instructions: activityRecurrences[0]?.activity_recurrence?.instructions,
                name: activityRecurrences[0]?.activity_recurrence?.name,
                policies: activityRecurrences[0]?.activity_recurrence?.activity_recurrence_policies.reduce((accumulator, policy) => {
                        accumulator.push(`${policy?.document?.id}.${policy?.section?.id || '0'}`);
                        return accumulator;
                    }, []),
                recurrenceType: activityRecurrences[0]?.activity_recurrence?.recurrence_type,
                reminderFrequencyAfterDue: activityRecurrences[0]?.activity_recurrence?.reminder_frequency_after_due,
                reminderFrequencyBeforeDue: activityRecurrences[0]?.activity_recurrence?.reminder_frequency_before_due,
                riskLabel: activityRecurrences[0]?.activity_recurrence?.risk_label,
                sendEmail: activityRecurrences[0]?.activity_recurrence?.send_email,
                startDate: activityRecurrences[0]?.activity_recurrence?.start_date,
                users: activityRecurrences[0]?.activity_recurrence?.user_activity_recurrences.reduce((accumulator, users) => {
                        var mapped = false;
                        for(const {value:loopSchema} of querySchemas){
                            if(users?.tenant_user.hasOwnProperty(`user_${loopSchema}`)){
                                mapped = true;
                                accumulator.push({
                                    assignmentType: users?.assignment_type,
                                    name: users?.tenant_user?.id ? `${users?.tenant_user[`user_${loopSchema}`]?.first_name} ${users?.tenant_user[`user_${loopSchema}`]?.last_name}` : `${users?.group?.name} Group`,
                                    type: users?.tenant_user?.id ? 'user' : 'group',
                                    value: users?.tenant_user?.id ? users?.tenant_user?.id : users?.group?.id,
                                });
                            }
                        }
                        if(!mapped){
                            accumulator.push({
                                assignmentType: users?.assignment_type,
                                name: users?.tenant_user?.id ? `${users?.tenant_user[`user_${schema}`]?.first_name} ${users?.tenant_user[`user_${schema}`]?.last_name}` : `${users?.group?.name} Group`,
                                type: users?.tenant_user?.id ? 'user' : 'group',
                                value: users?.tenant_user?.id ? users?.tenant_user?.id : users?.group?.id,
                            });
                        }
                        return accumulator;
                    }, []),
            }

            const activityLinks = [...activityLinksOne?.activityLinks, ...activityLinksTwo?.activityLinks, ...recurrenceLinks?.links];
            const caseLinks = [...activityLinksOne?.caseLinks, ...activityLinksTwo?.caseLinks];
            const logLinks = [...activityLinksOne?.logLinks, ...activityLinksTwo?.logLinks];
            const activityObject = {
                activityLinkages: activityLinks.sort((a,b) => { return a.id - b.id }),
                activityRecurrence,
                activityRiskLabel,
                activityUsersForRecurrence: activityUsers.reduce((accumulator, users) => {
                    accumulator.push({
                        assignmentType: users?.assignment_type,
                        name: users?.tenant_user?.id ? `${users?.tenant_user[`user_${schema}`]?.first_name} ${users?.tenant_user[`user_${schema}`]?.last_name}` : `${users?.group?.name} Group`,
                        type: users?.tenant_user?.id ? 'user' : 'group',
                        value: users?.tenant_user?.id ? users?.tenant_user?.id : users?.group?.id,
                    });
                    return accumulator;
                }, []),  
                caseLinkages: caseLinks,
                dropdownSelections: dropdownSelections,
                dropdowns: (activityType?.dropdown) ? normalizeDropdowns(activityType.dropdown, dropdownSelections) : [],
                dueDate: formatDate(dueDate),
                id: activityId,
                instructions: activityInstructions,
                logLinkages: logLinks,
                name: activityName,
                policyReference: policies,
                recurrenceType: activityRecurrences[0]?.activity_recurrence?.recurrence_type,
                recurrences: recurrenceLinks?.recurrences,
                schema, 
                startDate: formatDate(startDate),
                status: status,
                type: type,
                userGroup: userGroup,
                userStatus: userProgress
            };
            
            const history = resolveHistory({activityHistory, querySchemas, schema});

            const groupForms = activityForms.filter(array => {
                return array.assignment === userGroup;
            });

            const forms =
                groupForms.length > 0
                    ? resolveForms(groupForms)
                    : {};
            
            const otherGroupForms = activityForms.filter(array => {
                return array.assignment !== userGroup;
            });
            
            function mapForms(assignment){
                const otherForms = 'form' in assignment
                    ? resolveForms([assignment])
                    : {};
                return otherForms
            }
            
            const otherForms = otherGroupForms.map(mapForms);

            const answers = groupForms.length > 0 ? resolveAnswers({baseSchema:schema, groupForms, querySchemas}) : {};

            const otherAnswers = otherGroupForms.reduce((accumulator, forms) => {
                const additionalQuestions = resolveAnswers({baseSchema:schema, groupForms:[forms], querySchemas});
                accumulator.questions.push(...additionalQuestions?.questions);
                return accumulator
            }, {questions:[]})
            const allAnswers = activityForms.reduce((accumulator, forms) => {
                const additionalQuestions = resolveAnswers({baseSchema:schema, groupForms:[forms], querySchemas});
                accumulator.questions.push(...additionalQuestions?.questions);
                return accumulator
            }, {questions:[]})

            accumulator = {
                activity: activityObject,
                allAnswers: allAnswers,
                allForms: activityForms,
                allPreviouslyAssignedUsers,
                answers,
                attachments,
                closed,
                comments: comments.sort((objA, objB) => (objA.createdAt < objB.createdAt) ? 1 : -1),
                deleted,
                entities,
                forms,
                hierarchical,
                history: history.sort(
                    (objA, objB) =>
                        Number(objB.createdAt) - Number(objA.createdAt)
                ),
                otherAnswers,
                otherForms,
                riskLabel,
                sendEmail,
                users
            };

            return accumulator;
        },
        {
            record: []
        }
    );

    const activityEntityData = data[`${schema}_activity_entity`].reduce(
        (accumulator, entity) => {
            accumulator.push({
                value: entity.entity_id
            });

            return accumulator;
        }, []
    )

    const activityRegulatoryReferenceData = data[`${schema}_activity_regulatory_reference`].reduce(
        (accumulator, ref) => {
            // accumulator.push({
            //     activityId: ref.activity_id,
            //     activityTypeId: ref.activity_type_id,
            //     id: ref.id,
            //     regulatoryReferenceId: ref.regulatory_reference_id,
            // });

            // accumulator.push({ value: ref.regulatoryReferenceId});
            accumulator.push(ref.regulatory_reference_id);

            return accumulator;
        }, []
    )
    
    const allEntityData = data[`${schema}_entity`].reduce(
        (accumulator, entity) => {
            accumulator.push({
                label: entity.name,
                value: entity.id
            })

            return accumulator;
        }, []
    )
    
    activityData.activityEntityData = activityEntityData;
    activityData.allEntityData = allEntityData;
    activityData.activityRegulatoryReferenceData = activityRegulatoryReferenceData;
    
    return activityData;
}

export default normalizeData;
